import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, ElephantLogo } from './icons'
import { LeftArrow } from './icons'
import parse from 'html-react-parser'

class Header extends Component {

  state = {
    offCanvas: false,
    offCanvasVpeg: false,
    dropdown: false,
    dropdownIndex: null,
    homeBannerScrolled: false,
    scrolled: false, 
    direction: 0, 
    prevDirection: 0, 
    prevScroll: 0,
    homeBannerExists: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _hideAll = () => {
    this.setState({
      offCanvas: false,
      offCanvasVpeg: false,
      dropdownIndex: null,
    })
  }

  _toggleOffCanvas = () => {
    this.setState({
      offCanvas: !this.state.offCanvas,
      offCanvasVpeg: false,
    })
  }

  _toggleVpeg = () => {
    this.setState({
      offCanvasVpeg: !this.state.offCanvasVpeg,
    })
  }

  _hiddenVpeg = () => {
    this.setState({
      offCanvasVpeg: false,
    })
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, scrolled, homeBannerScrolled, bannerScrolled, homeBannerExists } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    let homeBanner = document.querySelector('.home-banner')
    const homeBannerHeight = homeBanner?.offsetHeight
    const endMarginBottom = window?.innerHeight - 511
    const logoMarginBottom = Math.min(Math.max(0, curScroll), endMarginBottom)

    if (homeBanner) {
      homeBannerExists = true
      scrolled = false
      if (curScroll > logoMarginBottom) {
        homeBannerScrolled = true
      } else {
        homeBannerScrolled = false
      }
      if (curScroll > homeBannerHeight) {
        bannerScrolled = true
      } else {
        bannerScrolled = false
      }
    } else {
      homeBannerExists = false
    }
    
    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return
    direction = curScroll > prevScroll ? 2 : 1
    
    prevScroll = curScroll

    requestAnimationFrame(() => {
      this.setState({
        scrolled, direction, prevDirection, prevScroll, homeBannerScrolled, bannerScrolled, homeBannerExists
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  render() {

    let { offCanvas, offCanvasVpeg, scrolled, homeBannerScrolled, bannerScrolled, homeBannerExists, dropdownIndex } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    }
    let dropProps = {
      onClick: this._hideAll,
      activeClassName: 'active',
    }

    let headerClass = 'header'
    if (offCanvas) headerClass += ' header--off-canvas'
    if (scrolled) headerClass += ' header--scrolled'
    if (homeBannerScrolled && homeBannerExists) headerClass += ' header--home-banner-scrolled'
    if (bannerScrolled && homeBannerExists) headerClass += ' header--banner-scrolled'
    if (this.props.pageContext.header) headerClass += ` header--${ this.props.pageContext.header }`

    return (
      <>
        <header className={ headerClass }>
          <div className='header__inner'>
            <Link to={`${this.props.pageContext.menu ? this.props.pageContext.menu.logoLink : '/' }`} title='Vantage Asset Management' className={ `header__logo${ this.props.pageContext.menu ? ' vpeg' : '' }` } { ...props }>
              { this.props.pageContext.menu &&
                <>
                  <ElephantLogo/>
                  { this.props.pageContext.menu.logo }
                </>
              }
              { !this.props.pageContext.menu && <Logo/> }
            </Link>
            <nav className='header__nav'>
              { this.props.pageContext.menu &&
                <ul>
                  { this.props.pageContext.menu?.menu.map((el, i) => (
                    <li key={ i }>
                      <Link to={ el.link.url } { ...props }>{ parse(el.link.title) }</Link>
                      { el.subMenu?.length > 0 &&
                        <ul>
                          { el.subMenu?.map((sub, s) => (
                            <li key={ s }>
                              <Link to={ sub.link.url } { ...dropProps }>{ parse(sub.link.title) }</Link>
                            </li>
                          )) }
                        </ul>
                      }
                    </li>
                  )) }
                </ul>
              }
              { !this.props.pageContext.menu &&
                <ul>
                 <li>
                    <Link to='/about/' { ...props }>About</Link>
                    <ul>
                      <li><Link to='/about/' { ...props }>About Vantage</Link></li>
                      <li><Link to='/our-business/' { ...props }>Our Business</Link></li>
                      <li><Link to='/people/' { ...props }>Our People</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/vantage-approach/' { ...props }>Strategy</Link>
                    <ul>
                      <li><Link to='/vantage-approach/' { ...props }>Vantage Approach</Link></li>
                      <li><Link to='/vantage-solutions/' { ...props }>Vantage Solutions</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/vpeg-6/' { ...props }>Funds</Link>
                    <ul>
                      <li><Link to='/vpeg-6/' { ...props }>VPEG 6 - Open </Link></li>
                      <li><Link to='https://vpeg5.info/' { ...props }>VPEG 5</Link></li>
                      <li><Link to='https://vpeg4.info/' { ...props }>VPEG 4</Link></li>
                      <li><Link to='https://vpeg3.info/' { ...props }>VPEG 3</Link></li>
                      <li><Link to='https://vpeg2.info/' { ...props }>VPEG 2</Link></li>
                      <li><Link to='https://vpeg.info/' { ...props }>VPEG</Link></li>
                      <li><Link to='/vpeso/' { ...props }>Secondaries</Link></li>
                    </ul>
                  </li>
                
                  <li>
                    <Link to='/news/' { ...props }>Resources</Link>
                    <ul>
                      <li><Link to='/news/?category=insights' { ...props }>Insights</Link></li>
                      <li><Link to='/news/?category=company-news' { ...props }>Company News</Link></li>
                      <li><Link to='/news/?category=fund-news' { ...props }>Fund News</Link></li>
                    </ul>
                  </li>
                  <li><Link to='/contact/' { ...props }>Contact</Link></li>
                </ul>
              }
            </nav>
            <div className='header__button'>
              <button onClick={ this._toggleOffCanvas } className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className='lines'></span>
              </button>
            </div>
          </div>
        </header>
        <div className={ `off-canvas ${ offCanvas && 'off-canvas--active' }` }>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              { this.props.pageContext.menu &&
                <ul>
                  { this.props.pageContext.menu?.menu.map((el, i) => (
                    <li key={ i }>
                      { el.subMenu?.length > 0 &&
                        <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: i }) }>
                          { parse(el.link.title) }
                        </button>
                      }
                      { !el.subMenu?.length > 0 &&
                        <Link to={ el.link.url } { ...props }>{ parse(el.link.title) }</Link>
                      }
                      { el.subMenu?.length > 0 &&
                        <ul className={`${dropdownIndex === i ? 'active' : ''}`}>
                          <li className='off-canvas__title'>{ parse(el.link.title) }</li>
                          { el.subMenu?.map((sub, s) => (
                            <li key={ s }>
                              <Link to={ sub.link.url } { ...dropProps }>{ parse(sub.link.title) }</Link>
                            </li>
                          )) }
                          <li className='off-canvas__back'>
                            <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: null }) }>
                              <LeftArrow />
                              <span>Main Menu</span>
                             </button>
                          </li>
                        </ul>
                      }
                    </li>
                  )) }
                  { this.props.pageContext.menu &&
                    <li className='off-canvas__back'>
                      <Link className='off-canvas__dropdown' to='/' { ...dropProps }>
                        <LeftArrow />
                        <span>Main Site</span>
                      </Link>
                    </li>
                  }
                </ul>
              }
              { !this.props.pageContext.menu &&
                <ul>
                   <li>
                      <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: 0 }) }>
                        About
                      </button>
                      <ul className={`${dropdownIndex === 0 ? 'active' : ''}`}>
                        <li className='off-canvas__title'>About</li>
                        <li><Link to='/about/' { ...props }>About Vantage</Link></li>
                        <li><Link to='/our-business/' { ...props }>Our Business</Link></li>
                        <li><Link to='/people/' { ...props }>Our People</Link></li>
                        <li className='off-canvas__back'>
                          <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: null }) }>
                            <LeftArrow />
                            <span>Main Menu</span>
                          </button>
                        </li>
                      </ul>
                  </li>
                  <li>
                    <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: 1 }) }>
                      Strategy
                    </button>
                    <ul className={`${dropdownIndex === 1 ? 'active' : ''}`}>
                      <li className='off-canvas__title'>Strategy</li>
                      <li><Link to='/vantage-approach/' { ...props }>Vantage Approach</Link></li>
                      <li><Link to='/vantage-solutions/' { ...props }>Vantage Solutions</Link></li>
                      <li className='off-canvas__back'>
                        <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: null }) }>
                          <LeftArrow />
                          <span>Main Menu</span>
                        </button>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: 2 }) }>
                      Funds
                    </button>
                    <ul className={`${dropdownIndex === 2 ? 'active' : ''}`}>
                      <li className='off-canvas__title'>Funds</li>
                      <li><Link to='/vpeg-6/' { ...props }>VPEG 6 - Open </Link></li>
                      <li><Link to='https://vpeg5.info/' { ...props }>VPEG 5</Link></li>
                      <li><Link to='https://vpeg4.info/' { ...props }>VPEG 4</Link></li>
                      <li><Link to='https://vpeg3.info/' { ...props }>VPEG 3</Link></li>
                      <li><Link to='https://vpeg2.info/' { ...props }>VPEG 2</Link></li>
                      <li><Link to='https://vpeg.info/' { ...props }>VPEG</Link></li>
                      <li><Link to='/vpeso/' { ...props }>Secondaries</Link></li>
                      <li className='off-canvas__back'>
                        <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: null }) }>
                          <LeftArrow />
                          <span>Main Menu</span>
                        </button>
                      </li>
                    </ul>
                  </li>
                
                  <li>
                    <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: 3 }) }>
                      Resources
                    </button>
                    <ul className={`${dropdownIndex === 3 ? 'active' : ''}`}>
                      <li><Link to='/news/' { ...props }>View all</Link></li>
                      <li><Link to='/news/?category=insights' { ...props }>Insights</Link></li>
                      <li><Link to='/news/?category=company-news' { ...props }>Company News</Link></li>
                      <li><Link to='/news/?category=fund-news' { ...props }>Fund News</Link></li>
                      <li className='off-canvas__back'>
                        <button className='off-canvas__dropdown' onClick={ () => this.setState({ dropdownIndex: null }) }>
                          <LeftArrow />
                          <span>Main Menu</span>
                        </button>
                      </li>
                    </ul>
                  </li>
                  <li><Link to='/contact/' { ...props }>Contact</Link></li>
                </ul>
              }
            </nav>

            { !this.props.pageContext.menu &&
              <button onClick={ this._toggleVpeg } className='off-canvas__vpeg'>
                <ElephantLogo/>
                FUNDS
              </button>
            }
          </div>
        </div>

        <div className={ `off-canvas off-canvas__vpegs ${ offCanvasVpeg && 'off-canvas--active' }` }>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <button onClick={this._hiddenVpeg} className='off-canvas__back'><LeftArrow/>Back to main</button>
              <ul>
                <li><Link to='/vpeg-6/' { ...dropProps }>VPEG 6 <span>OPEN</span></Link></li>
                <li><Link to='https://vpeg5.info/' { ...dropProps }>VPEG 5 <span>CLOSED</span></Link></li>
                <li><Link to='https://vpeg4.info/' { ...dropProps }>VPEG 4 <span>CLOSED</span></Link></li>
                <li><Link to='https://vpeg3.info/' { ...dropProps }>VPEG 3 <span>CLOSED</span></Link></li>
                <li><Link to='https://vpeg2.info/' { ...dropProps }>VPEG 2 <span>CLOSED</span></Link></li>
                <li><Link to='https://vpeg.info/' { ...dropProps }>VPEG <span>CLOSED</span></Link></li>
                <li><Link to='/vpeso/' { ...dropProps }>Secondaries</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
